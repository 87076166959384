<template>
  <PageWithLayout>
    <search
      :searchDataList.sync="viewModel.searchDataList"
      :searchParams.sync="viewModel.searchParams"
      @onSearch="viewModel.onSearch()"/>
    <TableView v-if="viewModel.hasResult && viewModel.result.id">
      <template v-slot:tbl_colgroup>
        <col style="width:190px"/>
        <col/>
        <col style="width:190px"/>
        <col/>
      </template>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">회원 검색결과</strong>
        </div>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>ID</th>
          <td>
            <span class="txt_tbl">{{ viewModel.result.id }}</span>
          </td>
          <th>firfin User ID</th>
          <td>
            <span class="txt_tbl">{{ viewModel.result.firfinUserUid }}</span>
          </td>
        </tr>
        <tr>
          <th>이름</th>
          <td>
            <span class="txt_tbl">{{ viewModel.result.name }}</span>
          </td>
          <th>전화번호</th>
          <td>
            <span class="txt_tbl">{{ viewModel.result.phoneNumber }}</span>
          </td>
        </tr>
        <tr>
          <th>은행명</th>
          <td>
            <span class="txt_tbl">{{ viewModel.result.bankCompany }}</span>
          </td>
          <th>계좌번호</th>
          <td>
            <span class="txt_tbl">{{ viewModel.result.bankAccount }}</span>
          </td>
        </tr>
      </template>
    </TableView>
    <div v-if="!viewModel.result.id" class="area_empty">
      <p class="desc_empty">{{ viewModel.hasResult ? '검색결과가 없습니다' : '은행정보를 검색해주세요' }}</p>
    </div>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import Search from '@lemontree-ai/lemontree-admin-common-front/components/common/search/Search';
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
// viewModel
import CsUserByBankViewModel from '@/views/cs/user/bybank/viewModel/CsUserByBankViewModel'

export default {
  name: 'CsUserByBank',
  components:{
    PageWithLayout,
    Search,
    TableView,
  },
  beforeMount(){
    this.viewModel.init();
  },
  data(){
    return{
      viewModel: new CsUserByBankViewModel(),
    }
  },
}
</script>
<style scoped>
.board_comm::v-deep .tbl_comm table{min-width:100%;width:auto}
.board_comm::v-deep .tbl_comm table .txt_tbl{display:block;max-width:200px}
</style>