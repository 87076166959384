import router from '@/router';
import { setDataList, convertIdToText } from "@lemontree-ai/lemontree-admin-common-front/utils/selectDataUtils";
import { GET_REQUEST } from '@/services/http';
import { makeQueryStringByObject } from '@lemontree-ai/lemontree-admin-common-front/utils/urlUtils';
import { isValidPhoneNumber } from '@lemontree-ai/lemontree-admin-common-front/utils/validateUtils';
import store from '@lemontree-ai/lemontree-admin-common-front/store/index';
import apiPath from '@/services/apiPath';

export default class CsUserByBankViewModel {
  constructor() {
    this.searchDataList = [
      {
        title:'은행',
        type: 'SelectboxInput',
        grid: 100,
        isFull:true,
        dataList:[],
        thumnailKey:'logoUrl',
        value1:'',
        value2:'',
        isBlockUnValueType: true,
        valueType:'number'
      },
    ]
    this.boardData = {
      title:'회원 검색결과',
      drawDataList: [
        {
          title:'pay ID',
          width:'80px',
          value:'payUserId',
          isAlignLeft: true,
        },
        {
          title:'firfin User ID',
          width:'210px',
          value:'firfinUserUid',
          isAlignLeft: true,
        },
        {
          title:'family Group ID',
          width:'120px',
          value:'familyGroupId',
          isAlignLeft: true,
        },
        {
          title:'회원이름',
          width:'90px',
          value:'name',
          isAlignLeft: true,
        },
        {
          title:'역할(대표유무)',
          width:'', // 60px
          value:'familyRole',
          class: {
            classColorFilter:{
              name:'convertIdToColor',
              dataListName:'cs_user_role_korean',
              prev:'fw_bold tc'
            }
          },
          valueCustom:[
            { 
              type: 'value',
              value: 'familyRole',
              filter:{
                name:'convertIdToText',
                value:'user_role'
              },
            },
            {
              type: 'value',
              value:'familyPermission',
              filter:{
                name:'convertIdToText',
                value:'user_family_leader'
              },
            },
          ],
          isAlignLeft: true,
        },
        {
          title:'휴대전화',
          width:'130px',
          value:'phone',
          filter: { name:'convertPhoneNember' },
          isAlignLeft: true,
        },
        {
          title:'등록 일시',
          width:'148px',
          value:'firfinCreatedAt',
          filter: { name:'convertDateFormat', value:'YYYY-MM-DD HH:mm:ss' },
          isMobileTitle:true
        },
        {
          title:'pay 계정상태',
          width:'100px',
          value:'payUserStatus',
          filter:{
            name:'convertIdToText',
            value:'user_status_name'
          },
          badge: {
            badgeColorFilter:{
              name:'convertIdToColor',
              dataListName:'cs_user_status_name'
            },
            badgeFill: false
          },
        },
        {
          title:'firfin 계정상태',
          width:'110px',
          value:'firfinUserStatus',
          filter:{
            name:'convertIdToText',
            value:'user_status_name'
          },
          badge: {
            badgeColorFilter:{
              name:'convertIdToColor',
              dataListName:'cs_user_status_name'
            },
            badgeFill: false
          },
        },
        // {
        //   title:'card Application ID',
        //   width:'160px',
        //   value:'card.cardApplicationId',
        //   isAlignLeft: true,
        // },
        // {
        //   title:'카드번호',
        //   width:'160px',
        //   value:'card.cardNumber',
        //   isAlignLeft: true,
        // },
        // {
        //   title:'카드 상태',
        //   width:'80px',
        //   value:'card.status',
        //   isAlignLeft: true,
        // },
        // {
        //   title:'카드 생성 일시',
        //   width:'148px',
        //   value:'cardApplication.createdAt',
        //   filter: { name:'convertDateFormat', value:'YYYY-MM-DD HH:mm:ss' },
        //   isAlignLeft: true,
        // },
        // {
        //   title:'카드 등록 일시',
        //   width:'148px',
        //   value:'cardApplication.registeredAt',
        //   filter: { name:'convertDateFormat', value:'YYYY-MM-DD HH:mm:ss' },
        //   isAlignLeft: true,
        // },
      ],
      option:{
        isTotal: true,
        isSize: false,
        rowIdValue: 'payUserId'
      },
      markData:[
        {
          id : 'phone',
          value : ''
        }
      ]
    };
    this.hasResult = false;
    this.result = {};
    this.searchParams = {
      page:0,
      pageSize:10,
      direction: 'DESC'
    };
    this.paginationData = {
      totalCount:0,
      totalPage:0
    };
  }
  init(){
    this.getBankList();
  }
  onSearch(){
    this.getData();
  }
  getBankList(){
    const path = apiPath.CS_BANK_LIST;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      this.searchDataList[0].dataList = resultData.map(item => {
        let rObj = item;
        rObj.id = item.id;
        rObj.text = `${item.name} (${item.id})`;
        return rObj;
      });
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  getData(){
    if(!this.searchDataList[0].value1 || !this.searchDataList[0].value2){
      store.dispatch('commonToast/fetchToastStart', `은행정보를 정확하게 검색해주세요`);
      return
    }
    // this.boardData.markData[0].value = value
    const query = makeQueryStringByObject(this.searchParams);
    const path = `${apiPath.CS_USER_BY_BANK.format(this.searchDataList[0].value1,this.searchDataList[0].value2)}${query}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      this.result = resultData;
      this.result.bankCompany = convertIdToText(this.searchDataList[0].value1,this.searchDataList[0].dataList);
      this.result.bankAccount = this.searchDataList[0].value2;
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
    this.hasResult = true;
  }
}